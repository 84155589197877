/* non-translated CSS variables*/

:root {
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Proxima Nova", --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

$custom-colors: ( "ripe-banana": #FFF482, "endurance-blue": #141438, "action-blue": #363695, "physics-white": #F6F6F6, "almost-black": #030318, "a8-grey": #A8A8A8, "blushing-salmon": #E95252, "regular-white": #FFFFFF, "gizmo-grey": #90909B, "coral-red":#FA3E3E, "white-smoke":#FFF0F0);
$updated-standard-colors: ( "indigo": #6610f2, "purple": #6f42c1, "pink": #e83e8c, "red": #dc3545, "orange": #fd7e14, "yellow": #ffc107, "green": #28a745, "teal": #20c997, "cyan": #17a2b8, "white": #ffffff, "gray": #6c757d, "gray-dark": #343a40);
$map-colors: ( "primary": var(--almost-black), "secondary": var(--gizmo-grey), "success": var(--green), "info": var(--cyan), "warning": var(--yellow), "danger": var(--red), "light": #f8f9fa, "dark": var(--grey-dark));
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors: map-merge($theme-colors, $updated-standard-colors);
$theme-colors: map-merge($theme-colors, $map-colors);
@each $name,
$glyph in $theme-colors {
     :root {
        --#{$name}: #{$glyph};
    }
}
