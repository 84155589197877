#org-search-input,
#org-search-input:focus {
    box-shadow: none;
    border-color: #ced4da;
    height: 30px;
}

.select-organization-modal .container {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #FFF;
    border-color: var(--a8-grey);
    border-right: 0;
}

.select-organization-modal .org-search-input,
.select-organization-modal .org-search-input:focus {
    border-left: 0;
    border-color: var(--a8-grey);
}

.select-organization-modal .org-option-list {
    max-height: 52.5vh;
    overflow-y: scroll;
}