.new-account-card-hide-show-animation {
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.new-account-card-hide-show-animation.hide {
    visibility: hidden;
    display: none;
    opacity: 0;
}

.new-account-card-hide-show-animation.show {
    visibility:visible;
    opacity: 1;
}