.navBar>ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navBar>a {
    text-decoration: none;
    color: var(--regular-white)
}

.navBar {
    min-height: 24px;
    width: 100%;
    background-color: var(--almost-black);
}

:root .navbar {
    flex-wrap: nowrap;
    justify-content: flex-start;
}

:root .navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 80;
    height: 45px;
}

:root .navbar-container>.container {
    max-width: none;
}

#navbar-logo {
    width: 150px;
    height: 30px;
    margin-bottom: 0;
}

.navbar-container .top-nav-search-container {
    border-color: #212529 !important;
}

.top-nav-search-container.search-input-group.input-group {
    transform: scale(0.8);
    position: absolute;
    top: 3px;
    width: 256px;
    left: 304px;
}

#user-info-area {
    display: flex;
    place-content: flex-end;
    position: absolute;
    right: 0;
    width: 10%;
    float: right;
    min-width: 750px;
    padding-right: 1.5rem;
}

button.black-background.btn {
    border-width: 0px;
}

.navbar-nav {
    align-items: center;
    justify-content: right;
}

.user-info-item {
    height: 100%;
    border: 0px;
    align-items: center;
    position: relative;
    border: 0;
    justify-content: space-evenly;
    float: right;
}

.user-info-item>svg {
    margin-right: 7.5px;
}

.user-info-item.dropdown,
button.user-info-item{
    padding-top: 0;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

#org-nav-dropdown,
#user-name-dropdown {
    color: var(--physics-white);
    border-width: 0px;
}

.dropdown.btn:hover {
    background-color: #373b3e;
    border-radius: var(--bs-btn-border-radius);
}

.user-info-item .nav-name-box {
    display: inline-block;
    max-width: 10rem;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
}

.account-nav-dropdown .nav-link {
    color: initial;
}

.navbar-container .dropdown-toggle.nav-link>svg.icon-angle-down {
    transform: rotate(0deg) scale(0.5);
    margin-left: 0.5rem;
}

.navbar-container .dropdown-toggle.nav-link[aria-expanded=true]>svg.icon-angle-down {
    transform: rotate(180deg) scale(0.5);
}

.user-info-item .dropdown-menu a.dropdown-item a {
    color: var(--almost-black);
}

.user-info-item>.dropdown-toggle::after {
    display: none;
}

#tools-dropdown,
#account-nav-dropdown {
    color: var(--physics-white);
}

#tools-dropdown>svg,
#account-nav-dropdown>svg {
    top: -0.3rem;
}

.tooltip-color,
.modalSubTitle {
    color: var(--a8-grey);
}

div.dropdown-menu.show a.dropdown-item p {
    margin: 0;
}

#root .navbar-nav .nav-link {
    padding: 0;
}

div#user-info-area.container div.user-info-item.nav-item.show.dropdown a.dropdown-toggle.nav-link svg.icon-angle-down {
    transform: rotate(180deg) scale(0.5);
}

div#user-info-area.container div.user-info-item.nav-item.dropdown:not(.show) a.dropdown-toggle.nav-link svg.icon-angle-down {
    transform: rotate(0deg) scale(0.5);
}

.icon {
    stroke: var(--physics-white);
    width: 15px;
}

.account-nav-dropdown:focus>svg {
    transform: rotate(180deg);
}

.account-nav-dropdown:blur>svg {
    transform: rotate(180deg);
}

.dropdown-menu {
    top: 40px;
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(0px);
    transition: height .5s step-end;
    height: 0px;
}

.dropdown-menu.show {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: translateY(50px);
    height: auto;
}

.dropdown-item {
    padding: none;
}

.dropdown-menu>.dropdown-divider {
    margin: 0;
}

div.dropdown-menu.show {
    top: -7px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
}

div.user-info-item.user-name-dropdown div.dropdown-menu,
div.user-info-item.org-info-dropdown div.dropdown-menu {
    width: 30rem;
    position: fixed;
    top: 2px;
    left: calc(100vw - 30rem);
    right: 0;
}

#root .navbar-container nav {
    position: fixed;
    width: 100%;
    z-index: 9999;
}

#user-info-area i.pe-7s-accordion.icon-content::before {
    content: "\e66f";
}
