.recipient-type-sub-label{
    margin-left: 1.5rem;
    margin-bottom: 1rem;
}

.send-announce-button{
	float: right
}

.wysiwyg-editor{
	height: 28vh;
}

.wysiwyg-editor-wrapper{
	border: 1px solid #ced4da;
	border-radius: 0.375rem;
}