.left-filter-select-header button{
  background-color: var(--quicksight-grey) !important;
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
}

.accordion-header-filter-text {
  position: relative;
  top: -0.1875rem;
  left: 0.125rem;
}

.left-filter-select .accordion-body button {
  margin-left: 1.375rem;
  padding: 0.125rem 0.5rem;
}
