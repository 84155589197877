#organization-administration .info-pane{
  height: 80vh;
  overflow-y: scroll;
}

#organization-administration .info-pane .pane {
  margin-bottom: 1rem;
}

#organization-administration .info-pane .pane:not(:first-child) {
  margin-top: 1rem;
}

#organization-administration .info-pane .pane Button {
  margin-top: 0.25rem;
}

#organization-administration .view-switchboard-container {
  margin-top: 5rem;
}

#organization-administration .permisions-page-container {
  margin-left: 33vw;
}

#organization-administration .PageContent {
  margin-top: calc(45px + 1rem + 1rem);
  /*topnav + header + spacing*/
}

#organization-administration .subscription-link-filters {
  height: 10vh;
  overflow-y: scroll;
}

#organization-administration .subscription-link-filters::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

#organization-administration .subscription-link-filters::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}