#remove-notification-page .action-button {
  position: relative;
  top: 0.125rem;
}

#notification-admin-page .text-gizmo-grey svg path {
  fill: var(--gizmo-grey);
}

#notification-admin-page table thead,
#notification-admin-page table thead tr,
#notification-admin-page table thead tr th,
#notification-admin-page table thead button {
  background-color: var(--almost-black);
}

#notification-admin-page .page-header-container{
  height: auto;
}