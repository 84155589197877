.view-switchboard-container .form-check {
    position: relative;
    flex-direction: row-reverse;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--a8-grey);
    padding-left: 0;
    margin-top: 0.5rem;
}

.view-switchboard-container>* .form-switch .form-check-input {
    align-self: flex-start;
    border-color: var(--endurance-blue);
    font-size: 25px;
    display: inline-block;
}

.view-switchboard-container>* .form-check-input:checked {
    background-color: var(--endurance-blue);
}

.view-switchboard-container>* .form-check-label {
    display: inline-block;
    align-self: flex-end;
}

.view-switchboard-container hr {
    margin: 0;
    padding: 0;
}

.view-switchboard-container h3 {
    font-size: larger;
}

.view-switchboard-container h4 {
    font-size: large;
}

.view-switchboard-container p {
    font-size: medium;
}

.view-switchboard-container .menuGroup-row {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.view-switchboard-container .ViewSaveFeedback table {
    width: 100%;
    background: #FFF;
}

.view-switchboard-container .ViewSaveFeedback table th{
  padding: 0.5rem;
  border-bottom: 1px solid var(--a8-grey);
}

.view-switchboard-container .ViewSaveFeedback table td{
  padding: 0 0.5rem;
}

.view-switchboard-container .ViewSaveFeedback table tr:last-child td{
  padding-bottom: 0.5rem;
}