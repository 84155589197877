@font-face {
    font-family: "Proxima Nova";
    src: url("/fonts/Proxima Nova Font.otf");
}


/* very official marshall colors*/

:root {
    /* custom vars */
    --ripe-banana: #FFF482;
    --endurance-blue: #141438;
    --physics-white: #F6F6F6;
    --almost-black: #030318;
    --action-blue: #363695;
    --a8-grey: #A8A8A8;
    --blushing-salmon: #E95252;
    --coral-red: #FA3E3E;
    --dc-grey: #DCDCDC;
    --action-salmon: #BB2D3B;
    --regular-white: #FFFFFF;
    --gizmo-grey: #90909B;
    --quicksight-grey: #F5F5F5;
    --quicksight-grey-active: #dcdcdc;
    --highlight-color: #FBDDDD;
    --highlight-color-dark: #F7CCCC;
    /* bootstrap re-definition */
    --bs-dropdown-link-hover-bg: #e9ecef;
    /* standard vars */
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: var(--almost-black);
    --secondary: var(--gizmo-grey);
    --success: var(--green);
    --info: var(--cyan);
    --warning: var(--yellow);
    --danger: var(--red);
    --light: #f8f9fa;
    --dark: var(--gray-dark);
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/* modal variables */
.modal {
    --bs-modal-header-padding: 0.5rem 0.5rem;
}

/* modal close buttons */
:root .modal-header .btn-close {
    margin-right: -0.125rem;
}

/* some color classes */

.color-alert {
    color: var(--alert);
}

.color-gizmo-grey {
    color: var(--gizmo-grey);
}


/* some general stuff */

.spinner-border {
    margin: 0 7px 0 12px;
    padding: 0 12px 0 12px;
}

:root .ma-btn {
    background-color: var(--endurance-blue);
    color: var(--regular-white);
    border-color: var(--almost-black);
}

:root .ma-btn:hover {
    background-color: var(--a8-grey);
    color: var(--regular-white);
    border-color: var(--almost-black);
}

:root .ma-link {
    text-decoration: none;
    color: var(--blushing-salmon);
    text-decoration: none;
    transition: all 0.2s linear;
}

:root .ma-link:hover {
    color: var(--action-salmon);
    cursor: pointer;
}

:root .ma-btn-link {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
}

:root .ma-btn-link:hover {
    background: none;
}

:root .ma-icon {
    font-family: Pe-icon-7-stroke;
}

.text-blushing-salmon {
    color: var(--blushing-salmon);
}

.text-gizmo-grey {
    color: var(--gizmo-grey);
}

.text-a8-grey,
.muted {
    color: var(--a8-grey);
}

.text-coral-red {
    color: var(--coral-red);
}

.text-black {
    color: var(--almost-black);
}


/* some general stuff */

.display-grid {
    display: grid
}

.spinner-border {
    margin: 0 7px 0 12px;
    padding: 0 12px 0 12px;
}

.font-weight-bold {
    font-weight: bold;
}

.bg-black,
.black-background {
    background-color: var(--almost-black);
}

.bg-quicksights-grey,
.quicksights-grey-background {
    background-color: var(--quicksight-grey);
}

.bg-coral-red,
.coral-red-background {
    background-color: var(--coral-red);
}

:root .dropdown-menu,
:root .dropdown-menu[data-bs-popper] {
    margin-top: -0.5rem;
}

.title-button-container {
    text-align: right;
}

:root input:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    outline: none;
    border-color: var(--a8-grey);
}


/* overwrite what is in bootstrap */

.btn-primary {
    background-color: var(--endurance-blue);
    border-color: var(--endurance-blue);
}

.btn-primary:hover {
    background-color: var(--action-blue);
    border-color: var(--action-blue);
}

.btn-link {
    color: var(--endurance-blue);
}

.btn-link:hover {
    color: var(--action-blue);
}


/* fix / simplify the nav dropdown */

:root [data-rr-ui-dropdown-item]:not(:last-child) {
    border-bottom: 1px solid var(--a8-grey);
}


/* fix radio button color */

:root .form-check-input:checked {
    background-color: var(--blushing-salmon);
    border-color: var(--blushing-salmon);
}

:root .form-check-input:not(:checked):focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

:root .mock-Accordion {
    border-radius: calc(0.25rem - 1px);
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #FFF;
    overflow-anchor: none;
    border: 1px solid rgba(0, 0, 0, 0.125);
}


/* disabled button color */

.btn-primary[disabled] {
    background-color: var(--a8-grey);
    border-color: var(--a8-grey);
    color: #212529;
}


/* when you want more black... */

:root .keep-almost-black,
:root .keep-almost-black:hover {
    color: #212529;
}

.form-check,
.form-check>input,
.form-check>label {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.clickable {
    cursor: pointer;
}

.product-field {
    font-style: italic;
    color: var(--gizmo-grey);
}

.example-url {
    color: var(--green);
    /* color: #147045; */
}

.url-fragment {
    color: var(--coral-red);
}

.capitalize {
    text-transform: capitalize;
}


/* accordion */

// prevent chevron color from changing on active
#root .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

#root .accordion-button.collapsed {
    box-shadow: none;
}

#root .accordion-button:not(.collapsed) {
    color: var(--almost-black);
    background-color: var(--quicksight-grey);
    box-shadow: none;
}

/* Used for when the "(Select All)" checkbox is in a "mixed" state */
.checkbox-grey-bg input[type=checkbox] {
    background: var(--quicksight-grey-active);
}

/* dropdowns */

#root .dropdown-item.active {
    text-decoration: none;
    background-color: var(--quicksight-grey-active);
}

.dropdown-menu {
    --bs-dropdown-link-active-bg: var(--quicksight-grey-active);
}

#user-info-area>div {
    transition: background-color 100ms linear, color 100ms linear;
}


/* buttons */

#root {
    :not(.btn-check)+.btn:active,
    .btn-check:checked+.btn,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
        color: var(--bs-btn-active-color);
        background-color: var(--endurance-blue);
        border-color: var(--endurance-blue);
    }
}


/* sizing */

#root .mx-100 {
    max-width: 100%;
}

/* leftNav highlights */
#root .highlight {
    font-weight: 600;
    background: var(--highlight-color);
    border-radius: 0.5rem;
}

#root .leftNavActiveParent .accordion-button {
    font-weight: 700;
}

/* modal close "x" */
.modal .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--highlight-color);
    opacity: 1;
}

.modal textarea.form-control:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--highlight-color);
    border-color: var(--highlight-color);
    opacity: 1;
}

/* bootstrap tooltip adjustment */
.tooltip-inner {
    max-width: 480px;
}

/* hide stuff... */
.stealth-text {
    height: 0;
    width: 0;
    overflow: hidden;
}

/* scroll bars.. */

:root .allow-y-scroll {
    overflow-y: scroll;
}

:root ::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0);
    width: 6px;
}

:root ::-webkit-scrollbar-thumb {
    background: var(--a8-grey);
    border-radius: 3px;
}

/* sizing utility */
.minWidth-10-rem {
    min-width: 10rem;
}

.vh-75 {
    height: 75vh;
}

.max-vh-75 {
    max-height: 75vh;
}

.max-vh-50 {
    max-height: 50vh;
}
