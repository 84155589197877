#notification-create-page #new-message-textarea {
  min-height: 15rem;
}

#notification-create-page .notification-template-selector .template-select-label{
  display: inline-block;
  background: var(--dc-grey);
  padding: 0.4375rem 1rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

#notification-create-page .notification-template-selector .template-select{
  display: inline-block;
  width: calc(100% - 20rem);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#notification-create-page .notification-label {
  display: block;
  position: relative;
  margin-left: 0.75rem;
  font-size: 0.85rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: var(--dc-grey);
  width: auto;
}

#notification-create-page .notification-message {
  display: block;
  position: relative;
  margin-left: 0.75rem;
  width: calc(100% - 1.5rem);
  border-top-left-radius: 0;
}