th.table-header-with-arrow button svg{
  position: relative;
  top: -0.125rem;
}

th.table-header-with-arrow .up-arrow {
  margin-left: 1rem;
}

th.table-header-with-arrow .flipped {
  transform: scaleY(-1);
}

th.table-header-with-arrow,
th.table-header-with-arrow button {
  background: var(--almost-black);
  color: white;
  font-weight: 500;
}

th.table-header-with-arrow button {
  padding: 0;
  margin: 0;
}